<template>
    <SlideLayout :closeLink="closeLink">
        <div class="o-row u-text--center">
            <div
                :class="[
                    'o-extra-small--12',
                    'o-medium--8',
                    'o-medium--offset-2',
                    'o-large--6',
                    'o-large--offset-3',
                ]"
            >
                <div class="o-illustration o-illustration--104px">
                    <img
                        alt=""
                        :src="PersonIllustration"
                        class="o-illustration__img u-spacer--3"
                    >
                </div>
                <h1 class="u-spacer--1 o-text--heading-3">
                    {{ title }}
                </h1>
                <BaseMarkdown v-if="subtitle" :source="subtitle" class="u-spacer--4" />
                <div class="c-login__form-container">
                    <BaseForm
                        v-if="!shouldHideForm"
                        :onSubmit="onSubmit"
                        :submitButton="submitButton"
                        :shouldAllowResubmission="true"
                        :isLoaded="isLoaded"
                    >
                        <slot name="form-field"></slot>
                        <BaseSupportingTextError
                            :hasError="hasError"
                            :errorText="errorText"
                            class="u-spacer--3"
                        />
                    </BaseForm>
                    <slot name="after-form"></slot>
                </div>
            </div>
        </div>
    </SlideLayout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import analyticsMixin from '~coreModules/analytics/js/analytics';

import { LOGIN_MODULE_NAME, SET_CLOSE_LINK } from '~coreRoutes/login/js/login-store';

import SlideLayout from '~modules/core/components/layouts/SlideLayout.vue';
import BaseForm from '~coreModules/core/components/ui/forms/BaseForm.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';
import BaseSupportingTextError from '~coreModules/core/components/ui/form-elements/BaseSupportingTextError.vue';

import PersonIllustration from '~coreImages/illustration__person.png';

export default {
    name: 'LoginContainer',
    components: {
        BaseForm,
        SlideLayout,
        BaseMarkdown,
        BaseSupportingTextError,
    },
    mixins: [analyticsMixin],
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setCloseLink(from.path);
        });
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            default: '',
        },
        onSubmit: {
            type: Function,
            default: null,
        },
        errorText: {
            type: String,
            default: '',
        },
        submitButton: {
            type: Object,
            default: () => {},
        },
        shouldHideForm: {
            type: Boolean,
            default: false,
        },
        isLoaded: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            email: '',
            PersonIllustration,
        };
    },
    computed: {
        ...mapState({
            closeLink: state => state.closeLink,
        }),
        hasError() {
            return this.errorText !== '';
        },
    },
    methods: {
        ...mapMutations(LOGIN_MODULE_NAME, {
            setCloseLink: SET_CLOSE_LINK,
        }),
    },
};
</script>

<style lang="scss">

</style>
